import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import commaNumber from "comma-number";
import React from "react";

const BreakDown = ({ isOpen, onClose, payment, url }) => {

    const handlePay = () => {
        window.open(url)
    }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "sm", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
        Summary
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody py="32px">
         
          <Flex flexDir={"column"} gap="5px">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text color="#605D66B2" fontWeight={500} fontSize={"14px"}>
                Subtotal
              </Text>
              <Text color={"#060212"} fontWeight={600} fontSize={"16px"}>
               {payment?.currency} {commaNumber(payment?.baseAmount)}
              </Text>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text color="#605D66B2" fontWeight={500} fontSize={"14px"}>
                Service Fee
              </Text>
              <Text color={"#060212"} fontWeight={600} fontSize={"16px"}>
              {payment?.currency} {commaNumber(payment?.buyerMerchantFees)}
              </Text>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text color="#060212" fontWeight={500} fontSize={"16px"}>
                Order Total
              </Text>
              <Text color={"#060212"} fontWeight={600} fontSize={"20px"}>
              {payment?.currency} {commaNumber(payment?.totalPayable)}
              </Text>
            </Box>
          </Flex>

          <Button
            bg="#3F12C4"
            color="#fff"
            fontSize={"12px"}
            borderRadius={"10px"}
            _hover={{ bg: "#3F12C4" }}
            onClick={handlePay}
            mt="24px"
            fontWeight={500}
            w="full"
          >
            Pay
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BreakDown;
