import { extractErrors } from "../utils/helpers";
import api from "./dataService";

export const createOrder = async (doc) => {
  try {
    const res = await api.post(
      `/stores/orders/?user_id=${doc.user_id}&storeId=${doc.store}`,
      doc.data
    );
    return res?.data
  } catch (error) {
    return error;
  }
};

export const createMyBalanceCustomer = async (doc) => {
  try {
    const res = await api.post(`/payment-option/customer`, doc);
    return res?.data?.data;
  } catch (error) {
    const errorMessage = extractErrors(error.response);
    throw new Error(errorMessage);
  }
};

export const createMyBalancePayment = async (doc) => {
  try {
    const res = await api.post(`/payment-option/initiate`, doc);
    return res?.data?.data;
  } catch (error) {
    const errorMessage = extractErrors(error.response);
    throw new Error(errorMessage);
  }
};

export const getMyBalanceCustomers = async () => {
  try {
    const res = await api.get(`/payment-option/customers/?page=1&size=100`);
    return res?.data?.data?.data;
  } catch (error) {
    const errorMessage = extractErrors(error.response);
    throw new Error(errorMessage);
  }
};

export const getOrders = async (doc) => {
  try {
    const res = await api.get(
      `/stores/orders?page=${doc.page}&perPage=${doc.limit}&user_id=${doc.user_id}&customer_id=${doc.customer_id}&storeId=${doc.store}`
    );
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerWidget = async (doc) => {
  try {
    const res = await api.post(`/payment-option/customer/link`, doc)
    return res?.data?.data

  } catch (error) {
    console.log(error)
  }
}