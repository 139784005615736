import { Box, Button, Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import SummaryCheckout from "./component/summary-checkout";
import useStore from "../../../hooks/useStore";
import useCart from "../../../hooks/useCart";
import CartContent from "./component/cart-content";
import SignUpModal from "../blocks/auth/sign-up";
import { PaystackConsumer } from "react-paystack";
import UpdateProfile from "../blocks/profile/update";
import { Link } from "react-router-dom";
import Login from "../blocks/auth/login";
import SelectCurrency from "../blocks/cart/select-currency";
import Loader from "../blocks/cart/loader";
import BreakDown from "../blocks/cart/breakdown";

const CartII = () => {
  const { shipping, shippingStates } = useStore();
  const {
    selectShippingMethod,
    shippingMethod,
    tax,
    paymentMethod,
    handlePaymentMethod,
    cart,
    updateQuantity,
    removeItemFromCart,
    subTotal,
    total,
    showSignUp,
    closeSignUp,
    handleCheckout,
    onSuccess,
    paystackRef,
    customer,
    user,
    show,
    closeAddress,
    isAddress,
    address,
    token,
    handleSetAddress,
    isCreatingCustomer,
    isPayingBalance,
    login,
    resetLogin,
    switchModal,
    showStartButton,
    resetShowStartButton,
    handleStartButton,
    createOrderFn,
    payment,
    showPayment,
    url,
    closePayment,
  } = useCart();

  const config = {
    email: customer?.email_address,
    subaccount: user?.subAccountCode,
    amount: total * 100,
    bearer: "subaccount",
    reference: new Date().getTime().toString(),
    publicKey: process.env.REACT_APP_PAYSTACK_LIVE_KEY,
    onSuccess: (res) => onSuccess(res),
    onClose: () => console.log("closed"),
  };

  const onClose = () => {
    console.log("closed");
  };

  const submitStartButton = (doc) => {
    handleStartButton(doc.currency);
  };

  return (
    <Box>
      <Loader loading={createOrderFn?.isLoading} />
      <PaystackConsumer {...config}>
        {({ initializePayment }) => (
          <Button
            onClick={() => initializePayment(onSuccess, onClose)}
            ref={paystackRef}
            display={"none"}
          >
            help
          </Button>
        )}
      </PaystackConsumer>
      <BreakDown
        isOpen={showPayment}
        url={url}
        payment={payment}
        onClose={closePayment}
      />
      <UpdateProfile isOpen={show} onClose={closeAddress} />
      <SelectCurrency
        isOpen={showStartButton}
        onClose={resetShowStartButton}
        submitStartButton={submitStartButton}
      />
      <Login isOpen={login} onClose={resetLogin} switchModal={switchModal} />
      <SignUpModal
        isOpen={showSignUp}
        onClose={closeSignUp}
        switchModal={resetLogin}
      />
      <Container maxW={"container.xl"} py="150px">
        {cart?.length === 0 ? (
          <Box display={"grid"} placeItems={"center"} h="40dvh">
            <Box>
              <Text textAlign={"center"}>No Item in Cart</Text>
              <Link to="/">
                <Button
                  bg="#3F12C4"
                  fontWeight={500}
                  fontSize={"12px"}
                  color="#fff"
                  my="16px"
                >
                  Continue Shopping
                </Button>
              </Link>
            </Box>
          </Box>
        ) : (
          <Flex gap={"24px"} flexDir={{ base: "column", md: "row" }}>
            <Box flex={1} display={"flex"} flexDir={"column"} gap={"16px"}>
              {cart.map((cart) => (
                <CartContent
                  id={cart?.id}
                  cart={cart}
                  updateQuantity={updateQuantity}
                  removeItemFromCart={removeItemFromCart}
                />
              ))}
            </Box>

            <Box w={{ base: "full", md: "400px" }}>
              <SummaryCheckout
                shipping={shipping}
                selectShippingMethod={selectShippingMethod}
                shippingMethod={shippingMethod}
                tax={tax}
                paymentMethod={paymentMethod}
                handlePaymentMethod={handlePaymentMethod}
                subTotal={subTotal}
                total={total}
                cart={cart}
                handleCheckout={handleCheckout}
                address={address}
                isAddress={isAddress}
                token={token}
                handleSetAddress={handleSetAddress}
                isCreatingCustomer={isCreatingCustomer}
                isPayingBalance={isPayingBalance}
                shippingStates={shippingStates}
              />
            </Box>
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default CartII;
