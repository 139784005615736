import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import useStore from "./useStore";
import { getStoreTax } from "../service/store-details";
import useUser from "./useUser";
import {
  createMyBalanceCustomer,
  createMyBalancePayment,
  createOrder,
  getMyBalanceCustomers,
} from "../service/order-service";
import toast from "react-hot-toast";
import moment from "moment";
import { useLocation } from "react-router-dom";

const useCart = () => {
  const [shippingMethod, setShippingMethod] = useState({});
  const { store, user } = useStore();
  const [tax, setTax] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cart, setCart] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const { customer } = useUser();
  const [showSignUp, setShowSignUp] = useState(false);
  const token = JSON.parse(localStorage.getItem("store-token"));
  const paystackRef = useRef();
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState({});
  const [isAddress, setIsAddress] = useState(null);
  const location = useLocation();
  const redirectUrl = `${window.location.origin}${location.pathname}`;
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("status");
  const [login, setLogin] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [paymentProcessed, setPaymentProcessed] = useState(false);
  const [payment, setPayment] = useState({})
  const [showPayment, setShowPayment] = useState(false)
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (store?.business_name) {
      const getCart = async () => {
        const cart = await localStorage.getItem(store?.business_name);
        const storedCart = JSON.parse(cart);
        setCart(storedCart || []);
      };

      getCart().catch((error) => {
        console.log("Failed to get cart", error);
      });
    }
  }, [store?.business_name]);

  useEffect(() => {
    const address = {
      address: customer?.address,
      city: customer?.city,
      country: customer?.country,
      state: customer?.state,
    };
    setAddress(address);
    const allValuesEmpty = Object.values(address).every((value) => !value);
    setIsAddress(allValuesEmpty);
  }, [customer]);

  const removeItemFromCart = (doc) => {
    const updatedData = cart.filter((item) => item.id !== doc);
    localStorage.setItem(store.business_name, JSON.stringify(updatedData));
    window.dispatchEvent(new Event("cartUpdated"));
    setCart(updatedData);
  };

  const updateQuantity = (doc) => {
    if (doc.type === "increment") {
      const updatedData = cart.map((item) => {
        if (item.id === doc.id) {
          return { ...item, quantity: item.quantity + 1 };
        }

        return item;
      });
      localStorage.setItem(store.business_name, JSON.stringify(updatedData));
      setCart(updatedData);
    } else {
      const updatedData = cart.map((item) => {
        if (item.id === doc.id) {
          return { ...item, quantity: item.quantity - 1 };
        }

        return item;
      });
      localStorage.setItem(store.business_name, JSON.stringify(updatedData));
      setCart(updatedData);
    }
  };

  const selectShippingMethod = (doc) => {
    setShippingMethod(doc);
  };

  const handlePaymentMethod = (doc) => {
    setPaymentMethod(doc);
  };

  const getStoreTaxFn = useQuery({
    queryKey: ["getStoreTaxFn", store],
    queryFn: () => getStoreTax(store.id),
    enabled: store?.id ? true : false,
  });

  useEffect(() => {
    const total = cart.reduce((prev, curr) => {
      return prev + curr?.price * curr?.quantity;
    }, 0);
    setSubTotal(total);
  }, [cart]);

  useEffect(() => {
    if (getStoreTaxFn?.data?.length > 0) {
      const result = getStoreTaxFn?.data
        ?.filter((tax) => tax.status)
        ?.map((tax) => {
          const taxAmount = (parseInt(tax.taxPercentage) / 100) * subTotal;
          const amountFormatted = parseFloat(taxAmount.toFixed(2));
          return {
            ...tax,
            taxAmount: amountFormatted,
          };
        });
      setTax(result);
    }
  }, [getStoreTaxFn.data, subTotal]);

  useEffect(() => {
    if (subTotal > 0) {
      const deliveryFee = shippingMethod?.price ? shippingMethod?.price : 0;
      const subtotal = subTotal + deliveryFee;
      const totalAmount =
        subtotal + tax.reduce((total, tax) => total + tax.taxAmount, 0);

      setTotal(totalAmount);
    }
  }, [shippingMethod?.price, tax, subTotal]);

  const handleCloseSignUp = () => {
    setShowSignUp(!showSignUp);
  };

  const onSuccess = async (ref) => {
    const items = cart.map((data) => {
      return {
        product_id: data.id,
        quantity: data.quantity,
        size: data?.size || "",
        color: data?.color || "",
      };
    });

    let data = {
      customer_id: customer?.id,
      total_amount: total,
      items,
      shipping: shippingMethod,
      reference: ref?.reference || "",
      currency:
        paymentMethod === "paystack"
          ? "paystack"
          : paymentMethod === "startbutton"
            ? "startbutton"
            : "mybalance",
    };
    await createOrderFn.mutateAsync({
      user_id: user?.id,
      data,
      store: store?.id,
    });
  };

  const createOrderFn = useMutation({
    mutationKey: ["createOrderFn"],
    mutationFn: (doc) => createOrder(doc),
    onSuccess: (res) => {
      if (res.status !== true) {
        const error = res?.response?.data?.error;
        toast.error(error);
        return;
      }
      toast.success("Order Created!!");
      localStorage.removeItem(store.business_name);
      setCart([]);
      window.location.href = `/`;
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });


  useEffect(() => {
    if (query && query === "successful" && !paymentProcessed) {
      const finalizePayment = async () => {
        if (!customer || !total || !cart.length || !shippingMethod) {
          console.error("Missing data! Ensure all fields are populated.");
          return;
        }
        const data = {
          customer_id: customer?.id,
          total_amount: total,
          items: cart.map((data) => ({
            product_id: data.id,
            quantity: data.quantity,
            size: data?.size || "",
            color: data?.color || "",
          })),
          shipping: shippingMethod,
          currency:
            paymentMethod === "paystack"
              ? "paystack"
              : paymentMethod === "startbutton"
                ? "startbutton"
                : "mybalance",
        };

        await createOrderFn.mutateAsync({
          user_id: user?.id,
          data: data,
          store: store?.id,
        });
        setPaymentProcessed(true);
      };
      finalizePayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, cart, customer, total, shippingMethod, user, store]);

  const myBalanceCustomers = useQuery({
    queryKey: ["myBalanecCustomers"],
    queryFn: () => getMyBalanceCustomers(),
  });

  const createMyBalanceCustomerFn = useMutation({
    mutationKey: ["createMyBalanceCustomer"],
    mutationFn: (doc) => {
      const res = createMyBalanceCustomer(doc);
      return res;
    },
  });

  const initializeMyBalancePaymentFn = useMutation({
    mutationKey: ["initializeMyBalancePaymentFn"],
    mutationFn: (doc) => createMyBalancePayment(doc),
    onSuccess: (res) => {
      console.log(res)
      if (res.success === true) {
        setPayment(res?.data?.paymentBreakdown)
        setUrl(res?.data?.link)
        setShowPayment(true)
      }
    },
    onError: (error) => {
      toast.success(error.message);
    },
  });

  const handleSetAddress = () => {
    setShow(true);
  };

  const startMyBalance = async () => {
    try {
      let seller = {
        customerType: "SELLER",
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        phoneNumber: user?.phone_number?.replace("234", "0"),
      };

      let buyer = {
        customerType: "BUYER",
        email: customer.email_address,
        firstName: customer.first_name,
        lastName: customer.last_name,
        phoneNumber: customer?.phone_number?.replace("234", "0"),
      };

      const today = new Date();
      const nextWeek = new Date(today);
      const week = nextWeek.setDate(today.getDate() + 7);

      let sellerResponse = {};
      let buyerResponse = {};

      const sellerExists = myBalanceCustomers?.data?.some(
        (item) =>
          item.phoneNumber === seller.phoneNumber &&
          (item.userType === "SELLER" || item.userType === "CUSTOM")
      );

      const buyerExists = myBalanceCustomers?.data?.some(
        (item) =>
          item.phoneNumber === buyer.phoneNumber &&
          (item.userType === "BUYER" || item.userType === "CUSTOM")
      );

      if (!sellerExists) {
        sellerResponse = await createMyBalanceCustomerFn.mutateAsync(seller);
      }

      if (!buyerExists) {
        buyerResponse = await createMyBalanceCustomerFn.mutateAsync(buyer);
      }
      let data = {
        buyer: buyerResponse?.data?.email || buyer.email,
        redirectUrl: redirectUrl,
        entities: [
          {
            seller: sellerResponse?.data?.email || seller.email,
            items: cart.map((data) => {
              return {
                title: data.name,
                description: data.description,
                category: "GOODS",
                itemQuantity: data.quantity,
                amount: data.price * data.quantity,
                deliveryDate: moment(week).format("YYYY-MM-DD"),
              };
            }),
          },
        ],
        currency: "NGN",
      };

      await initializeMyBalancePaymentFn.mutateAsync(data);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleCheckout = () => {
    // if (Object.keys(shippingMethod).length === 0) {
    //   return;
    // }

    if (!token.token) {
      setShowSignUp(true);
      return;
    }

    if (!paymentMethod) {
      return;
    }

    if (paymentMethod === "paystack") {
      paystackRef?.current?.click();
    } else if (paymentMethod === "mybalance") {
      startMyBalance();
    } else if (paymentMethod === "startbutton") {
      setShowStartButton(true);
    }
  };

  const closeAddress = () => {
    setShow(!show);
  };

  const resetLogin = () => {
    setLogin(!login);
  };

  const switchModal = () => {
    setShowSignUp(true);
  };

  const resetShowStartButton = () => {
    setShowStartButton(!showStartButton);
  };

  const handleStartButton = (doc) => {
    if (window.SBInit) {
      window.SBInit({
        key: process.env.REACT_APP_STARTBUTTON_PUBLIC_KEY,
        amount: total,
        channels: ["card"],
        env: "test",
        success: (res) => {
          onSuccess(res);
        },
        close: () => {
          console.log("closed");
        },
        error: (res) => {
          console.log(res);
        },
        currency: doc,
        email: customer?.email_address,
      });
    }
  };

  const closePayment = () => {
    setShowPayment(false)
    setPayment({})
    setUrl('')
  }

  return {
    selectShippingMethod,
    shippingMethod,
    tax,
    paymentMethod,
    handlePaymentMethod,
    handleCheckout,
    cart,
    updateQuantity,
    removeItemFromCart,
    subTotal,
    total,
    showSignUp,
    closeSignUp: handleCloseSignUp,
    onSuccess,
    paystackRef,
    customer,
    user,
    closeAddress,
    show,
    address,
    isAddress,
    token: token?.token,
    handleSetAddress,
    isCreatingCustomer: createMyBalanceCustomerFn.isLoading,
    isPayingBalance: initializeMyBalancePaymentFn.isLoading,
    myBalanceCustomers,
    login,
    resetLogin,
    switchModal,
    showStartButton,
    resetShowStartButton,
    handleStartButton,
    createOrderFn,
    payment,
    showPayment,
    url,
    closePayment
  };
};

export default useCart;
